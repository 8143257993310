import { combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import { scanReducer, aliasBranchesReducer, loadCarriersReducer, stationSortingConfigAliasesReducer, loaderReducer, scanModeReducer, stationReducer, stationsReducer, errorReducer, userReducer, parcelCounterReducer, systemInfoReducer, userInfoReducer, reportsReducer, returnCodesReducer, bulkLabelReducer, authenticationReducer, } from './reducers';
import { configureStore } from '@reduxjs/toolkit';
var reducer = combineReducers({
    isAuthenticated: authenticationReducer,
    isLoading: loaderReducer,
    error: errorReducer,
    scan: scanReducer,
    aliasBranches: aliasBranchesReducer,
    sortingConfigAliases: stationSortingConfigAliasesReducer,
    scanMode: scanModeReducer,
    stationId: stationReducer,
    stations: stationsReducer,
    loadCarriers: loadCarriersReducer,
    user: userReducer,
    parcelCounter: parcelCounterReducer,
    systemInfo: systemInfoReducer,
    returnCodes: returnCodesReducer,
    userInfo: userInfoReducer,
    reports: reportsReducer,
    bulkLabel: bulkLabelReducer,
});
var store = configureStore({
    reducer: reducer,
    middleware: function (getDefaultMiddleware) { return getDefaultMiddleware().concat(thunk); },
    devTools: process.env.APP_ENV !== 'prod',
});
export default store;
