var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Header from './views/Header';
import ErrorPage from './views/ErrorPage';
import NotFound from './views/NotFound';
import SystemInformationBar from './views/SystemInformationBar';
import { getStations, hasRole, loadLanguage } from './redux/actions';
import { resetErrors } from './redux/actions/resetErrors';
import { getReturnCodes } from './redux/actions/returnCodes';
import sections from './views/sections';
import { logout } from './redux/reducers/authentication';
var AppRouter = function (props) {
    var hasRole = props.hasRole, getStations = props.getStations, loadLanguage = props.loadLanguage, getReturnCodes = props.getReturnCodes, error = props.error, isAuthenticated = props.isAuthenticated, admin = props.admin, grantedRoles = props.grantedRoles, logout = props.logout;
    var pathname = useLocation().pathname;
    useEffect(function () {
        hasRole();
        getStations();
        loadLanguage();
        getReturnCodes();
    }, []);
    useEffect(function () {
        props.resetErrors();
    }, [pathname]);
    return (_jsxs("div", __assign({ className: 'app' }, { children: [_jsx(Header, { admin: admin, grantedRoles: grantedRoles, logout: logout, isAuthenticated: isAuthenticated }), _jsx(SystemInformationBar, {}), _jsx("div", __assign({ className: 'page-wrapper' }, { children: error ? (_jsx(ErrorPage, { error: error })) : (_jsxs(Routes, { children: [_jsx(Route, { exact: true, path: '/', element: _jsx(Navigate, { to: '/scan', replace: true }) }), sections.map(function (section) { return (_jsx(Route, { path: section.path, element: section.component }, section.name)); }), _jsx(Route, { component: NotFound })] })) }))] })));
};
var mapDispatchToProps = {
    hasRole: hasRole,
    getStations: getStations,
    logout: logout,
    resetErrors: resetErrors,
    loadLanguage: loadLanguage,
    getReturnCodes: getReturnCodes,
};
var mapStateToProps = function (state) {
    var _a, _b;
    return ({
        error: state.error.stations,
        admin: state.user.admin,
        isAuthenticated: state.isAuthenticated,
        selectedLanguage: state.user.language,
        grantedRoles: (_b = (_a = state.userInfo.data) === null || _a === void 0 ? void 0 : _a.grantedRoles) !== null && _b !== void 0 ? _b : [],
    });
};
export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
