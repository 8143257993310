var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import store from './redux/store';
import { Provider } from 'react-redux';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
function render() {
    Sentry.init({
        dsn: 'https://659ced2a744a445598dc2c4e6d362b65@o37388.ingest.sentry.io/5722761',
        environment: process.env.REACT_APP_ENV,
    });
    if (process.env.AMPLITUDE_API_KEY) {
        amplitude.init(process.env.AMPLITUDE_API_KEY, {
            serverZone: 'EU',
            defaultTracking: {
                pageViews: {
                    trackHistoryChanges: 'all',
                },
            },
            trackingOptions: {
                carrier: true,
                deviceManufacturer: true,
                deviceModel: true,
                osName: true,
                osVersion: true,
                platform: true,
            },
        });
    }
    var queryClient = new QueryClient();
    var container = document.getElementById('root');
    var root = createRoot(container);
    root.render(_jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(App, {}) })) })));
}
render();
module.hot && module.hot.accept('App', render);
