import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserInfo } from '../redux/actions/userInfo';
import { getSystemInfo } from '../redux/actions/systemInfo';
import { changeTimeZone } from '../lib/util';
import SystemMessage from '../components/SystemMessage';
import PasswordExpiryWarning from '../components/PasswordExpiryWarning';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
var shouldShow = function (fromDate, toDate) {
    var from = changeTimeZone(new Date(fromDate));
    var to = changeTimeZone(new Date(toDate));
    var now = new Date();
    return from <= now && to >= now;
};
var SystemInformationBar = function () {
    var dispatch = useAppDispatch();
    var location = useLocation();
    var userInfo = useAppSelector(function (state) { return state.userInfo.data; });
    var systemInfoMessages = useAppSelector(function (state) { return state.systemInfo.data; });
    var isAuthenticated = useAppSelector(function (state) { return state.isAuthenticated; });
    var messages = [];
    // Reload messages whenever the URL path changes
    useEffect(function () {
        if (isAuthenticated) {
            void dispatch(getUserInfo());
        }
        void dispatch(getSystemInfo());
    }, [location.pathname, isAuthenticated]);
    if (systemInfoMessages) {
        systemInfoMessages
            .filter(function (message) { return shouldShow(message.fromDate, message.toDate); })
            .forEach(function (message) {
            var messageLevel = message.importantFromDate ? 'error' : 'warning';
            messages.push(_jsx(SystemMessage, { systemInfo: message, className: messageLevel }));
        });
    }
    if (userInfo === null || userInfo === void 0 ? void 0 : userInfo.mustChangePassword) {
        messages.push(_jsx(PasswordExpiryWarning, {}));
    }
    return (_jsx(_Fragment, { children: messages.map(function (message, i) { return (_jsx("span", { children: message }, "system-message-".concat(i))); }) }));
};
export default SystemInformationBar;
